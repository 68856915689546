<template>
  <div class="timeline-custom">
    <div class="date-time">
        <el-date-picker
            v-model="formData.date"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            @change="inputChange">
        </el-date-picker>
        <el-time-picker
            v-show="selectType == 1 || selectType == 20"
            v-model="formData.time"
            value-format="HH:mm:ss"
            :picker-options="{
            selectableRange: '08:00:00 - 21:00:00'
            }"
            placeholder="选择时间">
        </el-time-picker>
        <el-time-picker
            v-show="selectType == 2"
            is-range
            v-model="formData.timeSlot"
            @change="inputChange"
            value-format="HH:mm"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围">
        </el-time-picker>
    </div>
    <el-input
        type="textarea"
        placeholder="请输入备注事项"
        v-model="formData.textarea"
        @change="inputChange"
        maxlength="200"
        show-word-limit
        resize="none"
        >
        </el-input>
  </div>
</template>

<script>
export default {
    props: {
        selectType: {
            type: '',
            default: ''
        }
    },
    data() {
        return {
            formData: {
                date: '',
                time: '',
                timeSlot: '',
                textarea: ''
            }
        }
    },

    methods: {
        initFormData() {
            this.formData = {
                date: '',
                time: '',
                timeSlot: '',
                textarea: ''
            }
        },

        inputChange() {
            this.formData.selectType = this.selectType
            this.$emit('inputChange', this.formData)
        },

        clearInput() {
            this.initFormData()
        }
    },

    watch: {
        selectType(newVal) {
            this.initFormData()
            this.inputChange()
        }
    }
}
</script>

<style lang="scss">
.timeline-custom {
    .date-time {
        margin: 10px 0;
        .el-date-editor {
            margin-right: 10px;
        }
    }
}
</style>