<template>
  <de-dialog
    :isDialog="isFollowUpDialog"
    title="写跟进"
    class="followUp-dialog"
    @cancelDialog="cancelAddStuDialog"
    @confirmDialog="confirmAddStuDialog()"
    confirmText="保存"
  >
    <div class="ub">当前意向学员：{{ followUpStudent ? followUpStudent.student_name : "" }}</div>
    <time-line :list="activities" :type="2" @inputChange="inputChange" ref="timeLine" :key="isFollowUpDialog ? 1 : 0"></time-line>
  </de-dialog>
</template>

<script>
import DeDialog from "@/components/DeDialog.vue";
import TimeLine from "./TimeLine.vue";
export default {
  components: { DeDialog, TimeLine },
  props: {
    isFollowUpDialog: {
      type: Boolean,
      default: false,
    },

    followUpStudent: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      activities: [],
    };
  },

  methods: {
    cancelAddStuDialog() {
      this.$emit("cancelFollowUpDialog");
    },

    confirmAddStuDialog() {
      console.log(this.formData);
      if (!this.isDefine(this.formData)) {
        this.$message({
          message: "请选择选项并填写信息",
          type: "warning",
        });
        return;
      }

      if (!this.isDefine(this.formData.date)) {
        this.$message({
          message: "请选择日期",
          type: "warning",
        });
        return;
      }

      if (this.formData.selectType == 1 || this.formData.selectType == 20) {
        if (!this.isDefine(this.formData.time)) {
          this.$message({
            message: "请选择时间",
            type: "warning",
          });
          return;
        }
      } else {
        if (!this.isDefine(this.formData.timeSlot)) {
          this.$message({
            message: "请选择开始及结束时间",
            type: "warning",
          });
          return;
        }
      }

      this.confirmHttp();
    },

    confirmHttp() {
      this.$http
        .fetchPost(this.$api.StudentManageController, {
          type: 7,
          student_id: this.followUpStudent.user_id,
          school_id: this.followUpStudent.school_id,
          uid: this.userInfo.id,
          token: this.userInfo.token,
          role: this.userInfo.role,
          state: this.formData.selectType,
          describe: this.formData.textarea,
          date: this.formData.date,
          start_time: this.formData.selectType == 1 ? this.formData.time : this.formData.timeSlot[0],
          end_time: this.formData.selectType == 1 ? "" : this.formData.timeSlot[1],
        })
        .then((res) => {
          console.log(res);
          if (res.data.state == 200) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            this.getTimeline();
            this.$refs.timeLine.clearInput();
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {});
    },

    getTimeline() {
      this.$http
        .fetchPost(this.$api.StudentManageController, {
          type: 6,
          student_id: this.followUpStudent.user_id,
          school_id: this.userInfo.schoolId,
          uid: this.userInfo.id,
          token: this.userInfo.token,
        })
        .then((res) => {
          console.log(res);
          if (res.data.state == 200) {
            this.activities = res.data.data.data_list;
          }
        })
        .catch((err) => {});
    },

    inputChange(formData) {
      console.log(formData);
      this.formData = formData;
    },
  },

  watch: {
    isFollowUpDialog(newVal) {
      if (newVal) {
        console.log(this.followUpStudent);
        this.userInfo = this.getUserInfo();
        this.getTimeline();
      }
    },
  },
};
</script>

<style lang="scss">
.followUp-dialog {
  .el-dialog__body {
    padding: 10px 20px;

    .el-timeline {
      margin-top: 20px;
    }
  }
}
</style>
