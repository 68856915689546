<template>
    <de-dialog
        :isDialog="isBatchDialog"
        :title="title()"
        class="batch-dialog"
        @cancelDialog="cancelDialog"
        @confirmDialog="confirmDialog"
        confirmText="保存"
    >
        <el-form label-width="90px">
            <el-form-item class="student-list" label="当前学员：">
                <el-tag type="warning" v-for="(item, index) in actStudentArr" :key="index">{{item.student_name}}</el-tag>
            </el-form-item>
            <el-form-item label="当前班级：" v-if="batchDialogType == 1">
                <div class="class-list ub">
                    <class-cell  :isAct="index == beforeClassIndex" v-for="(item, index) in classArr" :key="index" :item="item" @click.native="selectBeforeClass(index)"></class-cell>
                </div>
            </el-form-item>
            <el-form-item label="转入班级：">
                <div class="screen-type">
                    <el-tag :type="screenTypeAct == index ? '' : 'info'" @click="screenTypeTap(index)" v-for="(item, index) in screenType" :key="index">{{item.name}}</el-tag>
                </div>
                <div class="screen-list">
                    <div v-if="screenList.length > 0">
                        <el-link :type="acreenSubTypeAct == index ? 'primary' : ''" @click="screenSubTypeTap(index)" :underline="false" v-for="(item, index) in screenList" :key="index">{{screenName(item)}}</el-link>
                    </div>
                    <div v-else>暂无班级</div>
                </div>
            </el-form-item>
            <el-form-item label="">
                <div class="class-list ub ub-wrap" v-if="screenList.length > 0">
                    <class-cell :isAct="index == afterClassIndex" v-for="(item, index) in screenList[acreenSubTypeAct].data_list" :key="index" :item="item" @click.native="selectAfterClass(index)"></class-cell>
                </div>
            </el-form-item>
        </el-form>
    </de-dialog>
</template>

<script>
import DeDialog from '../../../../components/DeDialog.vue'
import ClassCell from './ClassCell.vue'
export default {
  components: { DeDialog, ClassCell },
  props: {
      isBatchDialog: {
          type: Boolean,
          default: false
      },

      batchDialogType: {
          type: Number,
          default: 1
      },

      actStudentArr: {
        type: Array,
        default: () => {
            return []
        }
      },

      isBatchType: {
        type: Boolean,
        default: true
      }
  },

  data() {
      return {
          screenTypeAct: 0,
          screenType: [{
              name: '按老师',
              id: 1,
              list: []
          }, {
              name: '按课程',
              id: 2,
              list: []
          }, {
              name: '按时段',
              id: 3,
              list: []
          }],

          acreenSubTypeAct: 0,
          acreenSubTypeList: [],

          classArr: [],
          beforeClassIndex: null,
          afterClassIndex: null,
          lessonArr: []
      }
  },

  computed: {
      title() {
          return function() {
            console.log(this.actStudentArr)
            let isBatch = this.isBatchType ? '批量' : ''
            switch (this.batchDialogType) {
                case 1:
                    return isBatch + '转班'
                case 2:
                    return isBatch + '分班'
            
                default:
                    return ''
            }
          }
      },

      /**
       * 获取、提交 转班、分班数据
       * @param {type} Number 1 获取 2 提交
       */
      httpType() {
        return function(type) {
            switch (this.batchDialogType) {
                case 1:
                    // 转班
                    return type == 1 ? 12 : 14;
                case 2:
                    // 分班
                    return type == 1 ? 11 : 13;
            
                default:
                     return '';
            }
        }
      },

      screenList() {
        return this.screenType[this.screenTypeAct].list
        
        /* return function() {
            console.log(this.screenTypeAct)
            if (this.screenTypeAct == 2) {
                return this.screenType[this.screenTypeAct].list.filter((item) => {
                    return item.class_time_range != ''
                })
            }
            return this.screenType[this.screenTypeAct].list
        } */
      },

      screenName() {
        return function(item) {
            console.log(this.screenList)
            console.log(this.screenTypeAct)
            console.log(item)

            switch (this.screenTypeAct) {
                case 0: 
                    console.log('a')

                    return item.teacher_name ? item.teacher_name : '';
                case 1: 
                    console.log('b')

                    return item.class_sub_name;
                case 2: 
                    console.log('c')
                    return item.class_time_range;
                default:
                    break;
            }
        }
      },
  },

  created() {
    this.userInfo = this.getUserInfo();
    // this.getData()
  },

  methods: {
      cancelDialog() {
          this.$emit('cancelBatchDialog')
      },

      /**
       * 提交转班
       */
      confirmDialog() {
        if(this.beforeClassIndex === null && this.batchDialogType == 1) {
            this.$message({
                message: '请选择当前班级！',
                type: 'warning'
            })
            return
        }

        if(this.afterClassIndex === null) {
            this.$message({
                message: `请选择需要${this.batchDialogType == 1 ? '转入' : '分配'}的班级！`,
                type: 'warning'
            })
            return
        }

        if (this.batchDialogType == 2) {
            // 分班需要加上弹窗提示
            this.$confirm(`确定将学生分配到班级【${this.screenList[this.acreenSubTypeAct].data_list[this.afterClassIndex].class_name}】吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                console.log('aaa')
                this.submitChangeClass()
            }).catch(() => {
                // 取消         
            });
        } else {
            this.submitChangeClass()
        }
      },

      /**
       * 提交转班/分班
       */
      submitChangeClass() {
        console.log('bbb')
        let student_ids = this.actStudentArr.map((item) => {
            return item.user_id
        }).join('-');
        let params = {
            type: this.httpType(2),
            student_ids: student_ids,
            class_id: this.screenList[this.acreenSubTypeAct].data_list[this.afterClassIndex].class_id,
            uid: this.userInfo.id,
            token: this.userInfo.token,
            pid: this.screenList[this.acreenSubTypeAct].data_list[this.afterClassIndex].pid,
            sub_id: this.screenList[this.acreenSubTypeAct].data_list[this.afterClassIndex].sub_id,
            school_id: this.userInfo.schoolId,
        }
        
        if (this.httpType(2) == 14) {
            params.old_class_id = this.classArr[this.beforeClassIndex].class_id
        }

        console.log(params)
        this.$http
            .fetchPost(this.$api.StudentManageController, params)
            .then((res) => {
                console.log(res)
                if (res.data.state == 200) {
                    this.$message({
                        message: `${this.httpType(2) == 13 ? '分班' : '转班'}成功`,
                        type: 'success'
                    })
                    this.cancelDialog()
                    this.$emit('refresh')
                } else {
                    this.showErrorMsg(this.getMesage(res.data.state));
                }
            }).catch((err) => {
                console.log(err)
            })
      },

      /**
       * 获取共同班级
       */
      getClass() {
        let defaultStudent = this.actStudentArr[0]
        let classArr = []
        for (let i = 0; i < defaultStudent.student_subjects.length; i++) {
            let index = ''
            let j = 0
            for ( j; j < this.actStudentArr.length; j++) {
                index = this.actStudentArr[j].student_subjects.findIndex((item) => {
                    return item.class_id == defaultStudent.student_subjects[i].class_id
                })

                if (index == -1) {
                    break;
                } else {
                    if (j == this.actStudentArr.length - 1) {
                        classArr.push(defaultStudent.student_subjects[i])
                    }
                }
            }
        }
        console.log(classArr)
        this.classArr = classArr
        console.log(this.classArr)

      },

      /**
       * 获取共同课程的班级
       */
      getLesson() {
        let lessonArr = []
        let defaultStudent = this.actStudentArr[0]
        if (defaultStudent.student_subjects.length == 0) {
            if (defaultStudent.pid_subid == '') {
                lessonArr = []
            } else {
                let defaultStudentSubName = defaultStudent.pid_subid.split('_')
                for (let i = 0; i < defaultStudentSubName.length; i++) {
                    let index = ''
                    let j = 0
                    for ( j; j < this.actStudentArr.length; j++) {
                        index = this.actStudentArr[j].pid_subid.split('_').findIndex((item) => {
                            return item == defaultStudentSubName[i]
                        })

                        if (index == -1) {
                            break;
                        } else {
                            if (j == this.actStudentArr.length - 1) {
                                lessonArr.push(defaultStudentSubName[i])
                            }
                        }
                    }
                }
            }
        } else {
            // 获取相同班级
            let sameLessonArr = []
            for (let i = 0; i < defaultStudent.student_subjects.length; i++) {
                let sameClassArr = []
                console.log(defaultStudent.student_subjects[i])
                for (let j = 0; j < this.actStudentArr.length; j++) {
                    let index = this.actStudentArr[j].student_subjects.findIndex((item) => {
                        return item.class_id == defaultStudent.student_subjects[i].class_id
                    })
                    sameClassArr.push(this.actStudentArr[j].student_subjects[index])
                    if (index == -1) {
                        sameClassArr = []
                        break;
                    }
                }
                console.log(sameClassArr)

                if(sameClassArr.length > 0) {
                    let defaultStudent2 = sameClassArr[0]
                    let defaultStudentSubName2 = defaultStudent2.pid_subid.split('_')
                    console.log(defaultStudentSubName2)
                    for (let k = 0; k < defaultStudentSubName2.length; k++) {
                        let index2 = ''
                        let z = 0
                        for ( z; z < sameClassArr.length; z++) {
                            index2 = sameClassArr[z].pid_subid.split('_').findIndex((item) => {
                                return item == defaultStudentSubName2[k]
                            })

                            if (index2 == -1) {
                                break;
                            } else {
                                if (z == sameClassArr.length - 1) {
                                    lessonArr.push(defaultStudentSubName2[k])
                                }
                            }
                        }
                    }
                }
            }
        }
        this.lessonArr = lessonArr
      },

      /**
       * 获取分/转班数据
       */
      getData() {
        let student_ids = this.actStudentArr.map((item) => {
            return item.user_id
        }).join(',');

        let pid_subid =  this.lessonArr.map((item) => {
            return item
        }).join('_');

        if (pid_subid == '') {
            this.$message({
                // message: `当前所选学生所在班级，无法使用${this.title()}操作`,
                message: '请先在报名档案里给该学员添加预报名课程',
                type: 'warning'
            })
            this.cancelDialog()
            return
        }

        let params = {
            type: this.httpType(1),
            student_ids: student_ids,
            pid_subid: pid_subid,
            school_id: this.userInfo.schoolId,
            uid: this.userInfo.id,
            token: this.userInfo.token,
            student_subjects: JSON.stringify(this.actStudentArr)
        }

        this.$http
            .fetchPost(this.$api.StudentManageController, params)
            .then((res) => {
                console.log(res)
                if (res.data.state == 200) {
                    this.screenType[0].list = res.data.data.teacher_data_list
                    this.screenType[1].list = res.data.data.course_data_list
                    this.screenType[2].list = res.data.data.time_data_list.filter((item) => {
                        return item.class_time_range != ''
                    })
                } else {
                    this.showErrorMsg(this.getMesage(res.data.state));
                }
            }).catch((err) => {
                
            })
      },

      /**
       * 筛选班级 - 类型
       * @param {Number} index 标签索引
       */
      screenTypeTap(index) {
          console.log('aaaaa')
          this.acreenSubTypeAct = 0
          this.afterClassIndex = null
          this.screenTypeAct = index
          console.log(this.screenList)

      },

      /**
       * 
       */
      screenSubTypeTap(index) {
        this.afterClassIndex = null
        this.acreenSubTypeAct = index
      },

      /**
       * 选择当前班级
       */
      selectBeforeClass(index) {
        console.log(index)
        this.beforeClassIndex = index
      },

      /**
       * 选择需要转入的班级
       */
      selectAfterClass(index) {
        console.log(index)
        this.afterClassIndex = index
      }
  },

  watch: {
    isBatchDialog(newVal) {
        console.log(newVal)
        if (newVal) {
            this.screenTypeAct = 0
            this.acreenSubTypeAct = 0
            this.acreenSubTypeList = []
            this.beforeClassIndex = null
            this.afterClassIndex = null
            this.getClass()
            this.getLesson()
            this.getData()
            console.log(this.actStudentArr)
        }
    }
  }
}
</script>

<style lang="scss">
.batch-dialog {
    .el-dialog {
        min-width: 750px;
        .el-form {
            .el-form-item{
                .el-tag {
                    margin-right: 10px;
                }
            }

            .screen-type {
                .el-tag {
                    cursor: pointer;
                }   

                .el-tag.act {

                }
            }

            .screen-list {
                .el-link {
                    margin-right: 15px;
                }
            }
        }   
    }
}
</style>