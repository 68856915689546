<template>
  <div class="table-formal ub-f1">
    <el-table
      :data="userList"
      height="100%"
      border
      ref="multipleTable"
      style="width: 100%"
      :cell-class-name="cellClass"
      :row-class-name="rowClass"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="50" v-if="isManage" :selectable="selectable"> </el-table-column>
      <el-table-column prop="date" width="100" align="center">
        <template slot="header">
          <div>姓名</div>
          <div>年龄</div>
        </template>
        <template scope="scope">
          <div>{{ scope.row.student_name }}</div>
          <div class="margin_top_2">{{ scope.row.nian }}岁</div>
        </template>
      </el-table-column>
      <el-table-column prop="tel" label="手机号" align="center" width="120"> </el-table-column>
      <el-table-column align="center" width="200">
        <template slot="header">
          <div>{{ activeTabName == "formal" ? "在学课程" : "预报名课程" }}</div>
        </template>
        <template scope="scope">
          <div v-if="scope.row.student_subjects.length > 0">
            <div v-for="(item, index) in scope.row.student_subjects" :key="index">{{ item.class_sub_name }}</div>
          </div>
          <div v-else>{{ scope.row.student_sub_name }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" width="130">
        <template slot="header">
          <div>剩余课次/总课次</div>
        </template>
        <template scope="scope">
          <div>{{ scope.row.balance_hour }}/{{ scope.row.total_hour }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" width="100">
        <template slot="header">
          <div>
            状态
            <el-tooltip class="item" effect="dark" placement="top-start" v-if="activeTabName == 'informal'">
              <div slot="content">
                游客：已获得手机号，未到校<br />咨询生：已到校，还未上体验课<br />体验生：已上体验课，还未报名<br />结业已流失：已流失的正式学员
              </div>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </div>
        </template>
        <template scope="scope">
          <div>{{ stuState(scope.row.join_state) }}</div>
        </template>
      </el-table-column>
      <el-table-column :label="activeTabName == 'formal' ? '所在班级/班级ID' : '试听班级/班级ID'" align="center" width="160">
        <template scope="scope">
          <div class="con" v-if="scope.row.student_subjects.length > 0">
            <div v-for="(item, index) in scope.row.student_subjects" :key="index">
              {{ item.class_name + "/" + item.class_id }}
            </div>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column label="班级老师" align="center">
        <template scope="scope">
          <div class="con" v-if="scope.row.student_subjects.length > 0">
            <div v-for="(item, index) in scope.row.student_subjects" :key="index">
              {{ item.teacher_name }}
            </div>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column label="实际出勤/应出勤" align="center">
        <template scope="scope">
          <div class="con" v-if="scope.row.student_subjects.length > 0">
            <div v-for="(item, index) in scope.row.student_subjects" :key="index">
              {{ item.records + "/" + item.total }}
            </div>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" min-width="160">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="setClass(scope.row, 1)" v-if="isChangeClass(scope.row, 1) && isHeadmaster">转班</el-button>
          <el-button type="text" size="small" @click="setClass(scope.row, 2)" v-if="isChangeClass(scope.row, 2) && isHeadmaster">分班</el-button>
          <el-button @click="lookTap(scope.row)" type="text" size="small">查看</el-button>
          <el-button @click="writeFollowup(scope.row)" type="text" size="small" v-if="activeTabName == 'informal' && userInfo.role == 3">写跟进</el-button>
          <el-button @click="deleteBtn(scope.row)" type="text" size="small" v-if="(isHeadmaster || isAdmin) && activeTabName == 'informal'"
            >移入低意向生</el-button
          >
          <el-button type="text" size="small" @click="setStudentArchives(scope.row)" v-if="isHeadmaster || (isAdmin && scope.row.school_id < 0) || isTeacher">{{
            isEditText
          }}</el-button>

          <!-- 续费 校长端才显示 -->
          <el-button v-if="activeTabName == 'formal' && isHeadmaster" type="text" size="small" @click="doExpenditureAction(scope.row)">续费</el-button>

          <!-- 标记流失 老师和校长都显示 -->
          <el-button
            v-if="activeTabName == 'formal' && (isHeadmaster || isTeacher)"
            class="red_txt"
            type="text"
            size="small"
            @click="doMarkLossAction(scope.row)"
            >标记流失</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
//标记流失
import { markLoss } from "@/api/Api.js";
export default {
  props: {
    isManage: {
      type: Boolean,
      default: false,
    },

    userList: {
      type: Array,
      default: () => {
        return [];
      },
    },

    batchDialogType: {
      type: Number,
      default: 1, // 1转班 2分班
    },

    userInfo: {
      type: Object,
      default: () => {},
    },

    activeTabName: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      tableData: [],

      selectArr: [],
    };
  },

  computed: {
    stuState() {
      return function (state) {
        switch (state) {
          case -1:
            return "低意向生";
          case -3:
            return "结业已流失";
          case 0:
            return "游客";
          case 1:
            return "咨询生";
          case 2:
            return "体验生";
          case 3:
            return "待分班";
          case 4:
            return "在读";
          case 5:
            return "结业";
          default:
            return "-";
        }
      };
    },

    cellClass() {
      return function (item) {
        if (item.column.label == "所在班级/班级ID" || item.column.label == "班级老师" || item.column.label == "实际出勤/应出勤") {
          return "line";
        }
      };
    },

    rowClass() {
      return function (item) {
        if (item.row.balance_hour < 6) {
          return "hour-warning";
        }
      };
    },

    /**
     * 转班
     * a、有班级；b、状态为在读的学生
     * 分班
     * a、结业；b、待分班状态的学生；
     * @param {Object} item 学生数据
     * @param {Number} type 1 转班 2 分班
     */
    isChangeClass() {
      return function (item, type) {
        if (type == 1) {
          return item.student_subjects.length > 0 && item.join_state == 4;
        } else if (type == 2) {
          return item.join_state == 3;
        }
      };
    },

    /**
     * 老师
     */
    isTeacher() {
      return this.userInfo.role == 1;
    },

    /**
     * 校长
     */
    isHeadmaster() {
      return this.userInfo.role == 3;
    },

    /**
     * 管理员
     */
    isAdmin() {
      return this.userInfo.role == 4 || this.userInfo.role == 5;
    },

    /**
     * 编辑文字
     */
    isEditText() {
      return this.isTeacher ? "编辑" : "报名档案管理";
    },

    /**
     * 在学课程
     */
    studentLesson() {
      return function (item) {
        if (item.student_subjects.length > 0) {
          return item.class_sub_name;
        } else {
          return item.student_sub_name;
        }
      };
    },
  },

  methods: {
    handleSelectionChange(val) {
      this.$emit("multipleSelection", val);
    },

    /**
     * 转/分班
     */
    setClass(item, type) {
      this.$emit("showBatchDialog", item, type);
    },

    /**
     * 查看
     */
    lookTap(item) {
      this.$router.push({
        path: "/Home/StuRecord/StudentInfo",
        query: { userInfo: JSON.stringify(item) },
      });
    },

    /**
     * 写跟进
     */
    writeFollowup(item) {
      this.$emit("setFollowupDialog", item);
    },

    /**
     * 设置学生报名档案
     */
    setStudentArchives(item) {
      this.$emit("setStudentArchives", item);
    },

    /**
     * 筛选表格转班/分班/删除数据
     */
    selectable(row) {
      return this.batchDialogType == 1 || this.batchDialogType == 2 ? this.isChangeClass(row, this.batchDialogType) : true;
    },

    /**
     * 取消选择
     */
    toggleSelection() {
      this.$refs.multipleTable.clearSelection();
    },

    /**
     * 批量删除
     */
    batchDeleteBtn(data) {
      if (data.length == 0) {
        this.$message({
          message: "请选择需要删除的学生",
          type: "warning",
        });
        return;
      }

      this.deleteBtn(data);
    },

    /**
     * 删除事件
     */
    deleteBtn(item) {
      let student_name = "";
      if (item.constructor == Array) {
        let arr = [];
        item.forEach((value) => {
          arr.push(value.student_name);
        });
        student_name = arr.join("、");
      } else {
        student_name = item.student_name;
      }
      this.$confirm(`确认将学员：(${student_name})移入低意向生列表么？被移入的学员如果存在试听课班级将会自动从试听课班级移出`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteHttp(item);
        })
        .catch(() => {
          // 取消
        });
    },

    /**
     * 删除
     */
    deleteHttp(data) {
      let student_ids = "";
      if (data.constructor == Array) {
        let arr = [];
        data.forEach((value) => {
          arr.push(value.user_id);
        });
        student_ids = arr.join(",");
      } else {
        student_ids = data.user_id;
      }
      this.$http
        .fetchPost(this.$api.StudentManageController, {
          type: 8,
          student_ids: student_ids,
          uid: this.userInfo.id,
          token: this.userInfo.token,
          school_id: this.userInfo.schoolId,
          role: this.userInfo.role,
        })
        .then((res) => {
          if (res.data.state == 200) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.$emit("refresh");
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     * 续费的操作
     */
    doExpenditureAction(item) {
      this.$emit("showRenewDialog", item);
    },

    /**
     * 标记流失的操作
     */
    doMarkLossAction(item) {
      this.$confirm(`确认将${item.student_name}标记为流失状态吗?(流失状态的学员将被移入非正式学员列表)`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //请求服务器标记流失
          let arr = [];
          arr.push(item);
          let student_subjects = JSON.stringify(arr);
          markLoss(item.user_id, item.school_id, student_subjects).then((res) => {
            if (res.data.state == 200) {
              //执行成功,通知页面刷新
              this.$emit("refresh");
            } else {
              this.showWarnMsg(res.data.msg);
            }
          });
        })
        .catch(() => {
          // 取消
          this.showWarnMsg(res.data.msg);
        });
    },
  },
};
</script>

<style lang="scss">
.table-formal {
  width: 100%;
  height: 100%;

  .el-table {
    height: 100%;
    margin: 0 auto;

    .el-table__body-wrapper {
      .el-table__body {
        .el-table__row {
          .el-table__cell.line {
            .cell {
              padding: 0;
              .con {
                div {
                  padding: 10px 10px;
                  border-bottom: 1px solid #ebeef5;
                }

                div:last-child {
                  border: 0px;
                }
              }
            }
          }
        }
        .hour-warning {
          //background-color: #fbdaa5;
          color: #ff9100;
        }
      }
    }
  }

  .red_txt {
    color: red;
  }
}
</style>
