<template>
  <div class="class-item">
      <i class="el-icon-success" v-if="isAct"></i>
      <div class="class-sub-item ub"><label for="" class="label">班级名称：</label><div class="title ub-f1-item">{{item.class_name}}</div></div>
      <div class="class-sub-item ub"><label for="" class="label">班级课程：</label><div class="title ub-f1-item">{{item.class_sub_name}}</div></div>
      <div class="class-sub-item ub"><label for="" class="label">班级老师：</label><div class="title ub-f1-item">{{item.teacher_name}}</div></div>
  </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: () => {
                return {}
            }
        },

        isAct: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
.class-item {
    width: 230px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 20px 10px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;

    .el-icon-success {
        position: absolute;
        right: 5px;
        top: 5px;
        font-size: 24px;
        color: #409EFF;
    }

    .class-sub-item {
        line-height: 24px;
        margin-bottom: 5px;
        .label {
            width: 70px;
        }

        .title {
        }
    }
}

.class-item.act {

}
</style>