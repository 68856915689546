<template>
  <div class="student-menage ub ub-ver" v-loading="loading">
    <div class="handel-content ub ub-be">
      <el-button class="add-student" type="primary" size="medium" v-if="isHeadmaster" @click="showAddStuDialog">新增学员</el-button>
      <student-search
        ref="stu_search"
        class="ub-f1"
        :userInfo="userInfo"
        @searchTap="searchTap"
        :activeTabName="activeName"
        @refresh="getData"
      ></student-search>

      <!-- 删除和转班 -->
      <div class="batch-handel">
        <el-button
          size="medium"
          @click="batchHandle(3)"
          v-if="(!isManage || (isManage && batchDialogType == 3)) && activeName == 'informal' && isHeadmaster"
          :disabled="userList.length == 0"
          >{{ isManage ? "取消" : "" }}批量删除</el-button
        >
        <el-button size="medium" @click="batchHandle(1)" v-if="(!isManage || (isManage && batchDialogType == 1)) && activeName == 'formal' && isHeadmaster"
          >{{ isManage ? "取消" : "" }}批量转班</el-button
        >
        <el-button size="medium" @click="batchHandle(2)" v-if="(!isManage || (isManage && batchDialogType == 2)) && activeName == 'formal' && isHeadmaster"
          >{{ isManage ? "取消" : "" }}批量分班</el-button
        >
        <!-- 批量标记流失 -->
        <el-button
          size="medium"
          @click="batchHandle(3)"
          v-if="(!isManage || (isManage && batchDialogType == 3)) && activeName == 'formal' && (isHeadmaster || isTeacher)"
          >{{ isManage ? "取消" : "" }}批量标记流失</el-button
        >
        <el-button size="medium" @click="showBatchDialog" v-if="isManage && (isHeadmaster || isTeacher)" type="primary">下一步</el-button>
      </div>
    </div>
    <el-tabs class="ub ub-ver ub-f1" v-model="activeName" @tab-click="tabHandleClick" v-if="!loading && loadState == 200">
      <el-tab-pane :label="item.label" :name="item.name" v-for="(item, index) in tabs" :key="index">
        <table-formal
          ref="tableFormalChild"
          :userInfo="userInfo"
          :activeTabName="activeName"
          :isManage="isManage"
          :userList="userList"
          :batchDialogType="batchDialogType"
          @multipleSelection="multipleSelection"
          @setStudentArchives="showArchivesDialog"
          @refresh="getData"
          @showBatchDialog="showBatchDialog"
          @setFollowupDialog="showFollowupDialog"
          @showRenewDialog="showRenewDialog"
        ></table-formal>

        <!-- 底部的信息 -->
        <div class="student-num tx-l" v-if="isHeadmaster">
          <div v-if="activeName == 'formal'">
            <span>正式生总人数：{{ dataInfo.formal }}</span>
            <span>待分班人数：{{ dataInfo.daifenban }}</span>
            <span>在读人数：{{ dataInfo.zaidu }}</span>
            <span>结业人数：{{ dataInfo.jieye }}</span>
          </div>
          <div v-if="activeName == 'informal'">
            <span>非正式生总人数：{{ dataInfo.unformal }}</span>
            <span>游客人数：{{ dataInfo.youke }}</span>
            <span>咨询生人数：{{ dataInfo.zixun }}</span>
            <span>体验生人数：{{ dataInfo.tiyan }}</span>
          </div>
        </div>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-sizes="[6, 8, 10]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
    <el-empty :description="loadText" v-if="!loading && loadState != 200"></el-empty>
    <add-stu-dialog :isAddStuDialog="isAddStuDialog" @cancelAddStuDialog="cancelAddStuDialog" @addStuSucess="addStuSucess"></add-stu-dialog>
    <batch-dialog
      :isBatchDialog="isBatchDialog"
      :batchDialogType="batchDialogType"
      :isBatchType="isBatchType"
      :actStudentArr="actStudentArr"
      @cancelBatchDialog="cancelBatchDialog"
      @refresh="getData"
    ></batch-dialog>
    <student-archives-dialog
      :isStudentArchivesDialog="isStudentArchivesDialog"
      :studentArchivesInfo="studentArchivesInfo"
      @cancelArchivesDialog="cancelArchivesDialog"
    ></student-archives-dialog>
    <follow-up-dialog :isFollowUpDialog="isFollowUpDialog" :followUpStudent="followUpStudent" @cancelFollowUpDialog="cancelFollowUpDialog"></follow-up-dialog>
    <!-- 续费的Dialog -->
    <RenewDialog
      :teacherList="teacher_list"
      :actionItem="actionItem"
      @refresh="getData"
      @close="isShowRenewDialog = false"
      :showDialog="isShowRenewDialog"
      v-if="isShowRenewDialog"
    ></RenewDialog>
  </div>
</template>

<script>
import AddStuDialog from "./components/AddStuDialog.vue";
import BatchDialog from "./components/BatchDialog.vue";
import FollowUpDialog from "./components/FollowUpDialog.vue";
import StudentArchivesDialog from "./components/StudentArchivesDialog.vue";
import StudentSearch from "./components/StudentSearch.vue";
import TableFormal from "./components/TableFormal.vue";
// 续费的Dialog
import RenewDialog from "./components/RenewDialog.vue";
//标记流失
import { markLoss } from "@/api/Api.js";
export default {
  components: {
    AddStuDialog,
    StudentSearch,
    BatchDialog,
    TableFormal,
    StudentArchivesDialog,
    FollowUpDialog,
    RenewDialog,
  },
  data() {
    return {
      activeName: "formal",
      tabs: [
        {
          label: "正式学员",
          name: "formal",
          type: 3,
        },
        {
          label: "非正式学员",
          name: "informal",
          type: 4,
        },
      ],
      isAddStuDialog: false,
      isBatchDialog: false,
      isBatchType: true, // 是否是批量
      batchDialogType: 1,
      isManage: false,
      multipleSelectionValue: [], // 多选
      isStudentArchivesDialog: false,
      studentArchivesInfo: null,
      isFollowUpDialog: false,
      followUpStudent: null,
      // 提交选中学生数据
      actStudentArr: [],
      // search
      nameOrtel: "",
      state: "",
      // page
      currPage: 1,
      pageSize: 6,
      pageTotal: 0,
      // loading
      loading: true,
      loadState: 200,
      loadText: "",
      // data
      dataInfo: null,
      userList: null,
      //是否显示续费弹框的标识
      isShowRenewDialog: false,
      //操作的学生对象
      actionItem: null,
      //教师数据
      teacher_list: null,
    };
  },

  created() {
    this.userInfo = this.getUserInfo();
    if (this.isTeacher) {
      this.tabs[1].label = "体验生学员";
    }

    if (this.isAdmin) {
      // 管理员禁止查看正式学员
      console.log(this.tabs);
      this.tabs.splice(0, 1);
      this.activeName = "informal";
    }
    this.getData();
  },

  computed: {
    isTeacher() {
      // 老师
      return this.userInfo.role == 1;
    },

    isHeadmaster() {
      // 校长
      return this.userInfo.role == 3;
    },

    isAdmin() {
      // 管理员
      return this.userInfo.role == 4 || this.userInfo.role == 5;
    },
  },

  methods: {
    /**
     * 数据
     */
    getData() {
      let index = this.tabs.findIndex((item) => {
        return item.name == this.activeName;
      });
      let actPage = this.currPage - 1;
      this.loading = true;
      this.$http
        .fetchPost(this.$api.StudentManageController, {
          type: this.tabs[index].type,
          name_tel: this.nameOrtel,
          state: this.state,
          school_id: this.userInfo.schoolId,
          page: actPage,
          pagecount: this.pageSize,
          uid: this.userInfo.id,
          token: this.userInfo.token,
          role: this.userInfo.role,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.state == 200) {
            this.loadState = res.data.state;
            this.loadText = res.data.msg;
            this.pageTotal = Number(res.data.data.data_total);
            this.dataInfo = res.data.data;
            this.userList = res.data.data.data_list;
            this.teacher_list = res.data.data.teacher_list;
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.loading = false;
          this.loadState = -1;
          this.loadText = "网络错误";
        });
    },

    /**
     * tab切换
     */
    tabHandleClick(e) {
      this.$refs.stu_search.reset();
      this.nameOrtel = "";
      this.state = "";
      this.isManage = false;
      this.currPage = 1;
      this.getData();
    },

    /**
     * 新增学生 - 打开弹窗
     */
    showAddStuDialog() {
      this.isAddStuDialog = true;
    },

    /**
     * 新增学生 - 关闭弹窗
     */
    cancelAddStuDialog() {
      this.isAddStuDialog = false;
    },

    /**
     * 新增学生成功回调
     */
    addStuSucess() {
      this.isManage = false;
      this.currPage = 1;
      this.getData();
    },

    /**
     * 批量转/分班
     * @param {Number} type 1 转班 2 分班
     */
    batchHandle(type) {
      this.isManage = !this.isManage;
      this.batchDialogType = type;
      if (!this.isManage) {
        this.$refs.tableFormalChild[0].toggleSelection();
      }
    },

    /**
     * 批量分/转班/删除学生(非正式) - 打开弹窗
     * @param {Object} stuInfo
     * @param {Number} type 1 转班 2 分班 3 标记流失
     */
    showBatchDialog(stuInfo, type) {
      if (type) {
        this.batchDialogType = type;
        this.isBatchType = false;
      } else {
        this.isBatchType = true;
      }
      if (this.batchDialogType == 1 || this.batchDialogType == 2 || this.batchDialogType == 3) {
        // 转分班操作
        if ((!this.isDefine(stuInfo) || !stuInfo.hasOwnProperty("student_name")) && this.multipleSelectionValue.length == 0) {
          this.$message({
            type: "warning",
            message: "请选择需要批量处理的数据",
            showClose: true,
          });
          return;
        }

        if (stuInfo.hasOwnProperty("student_name")) {
          this.actStudentArr = [];
          this.actStudentArr.push(stuInfo);
        } else {
          this.actStudentArr = this.multipleSelectionValue;
        }
        if (this.batchDialogType == 1 || this.batchDialogType == 2) {
          this.isBatchDialog = true;
        } else if (this.batchDialogType == 3) {
          //获取标记流失的学员名称
          let idsArr = this.actStudentArr.map((item) => {
            return item.user_id;
          });
          let student_ids = idsArr.join(`,`);
          let namesArr = this.actStudentArr.map((item) => {
            return item.student_name;
          });
          //显示名字
          let names = namesArr.join("、");

          let showStr = namesArr.length > 1 ? `${names}${namesArr.length}名学员` : `${names}`;

          //打开批量标记流失的弹框
          this.$confirm(`确认将：${showStr}标记为流失状态吗?(流失状态的学员将被移入非正式学员列表)`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              //请求服务器 做标记流失的处理
              let student_subjects = JSON.stringify(this.actStudentArr);
              markLoss(student_ids, this.userList[0].school_id, student_subjects).then((res) => {
                if (res.data.state == 200) {
                  //执行成功,刷新界面
                  this.isManage = false;
                  this.currPage = 1;
                  this.getData();
                } else {
                  this.showWarnMsg(res.data.msg);
                }
              });
            })
            .catch(() => {
              // 取消
            });
        }
      } else {
        // 删除操作
        this.$refs.tableFormalChild[0].batchDeleteBtn(this.multipleSelectionValue);
      }
    },

    /**
     * 批量分/转 - 关闭弹窗
     */
    cancelBatchDialog() {
      this.isBatchDialog = false;
    },

    /**
     * 批量选中数据
     */
    multipleSelection(value) {
      this.multipleSelectionValue = value;
    },

    /**
     * 打开学生档案弹窗
     */
    showArchivesDialog(stuInfo) {
      this.isStudentArchivesDialog = true;
      this.studentArchivesInfo = stuInfo;
    },

    /**
     * 关闭学生档案弹窗
     * @param {Boolean} isRefresh 是否刷新数据
     */
    cancelArchivesDialog(isRefresh) {
      this.isStudentArchivesDialog = false;
      if (isRefresh) {
        this.getData();
      }
    },

    /**
     * 搜索
     * @param {Object} param - 搜索返回参数
     * @param {String} param.nameOrtel - 用户手机号或者姓名
     * @param {String} param.state - 学生状态
     */
    searchTap(param) {
      this.nameOrtel = param.nameOrtel;
      this.state = param.state;
      this.currPage = 1;
      this.getData();
    },

    /**
     * 分页 - 选择每页显示条数
     */
    handleSizeChange(size) {
      this.pageSize = size;
      this.getData();
    },

    /**
     * 分页 - 选择分页数
     */
    handleCurrentChange(page) {
      console.log(page);
      this.currPage = page;
      this.getData();
    },

    /**
     * 批量删除
     */
    batchDelete() {},

    /**
     * 跟进弹窗
     */
    showFollowupDialog(item) {
      this.isFollowUpDialog = true;
      this.followUpStudent = item;
    },

    /**
     * 关闭跟进弹窗
     */
    cancelFollowUpDialog() {
      this.isFollowUpDialog = false;
      this.getData();
    },

    /**
     * 续费弹框
     */
    showRenewDialog(item) {
      this.actionItem = item;
      this.isShowRenewDialog = true;
    },
  },
};
</script>

<style lang="scss">
.student-menage {
  height: calc(100vh - 106px);
  box-sizing: border-box;
  position: relative;
  margin-top: 20px;
  margin-left: 14px;
  margin-right: 8px;

  .handel-content {
    margin-bottom: 10px;
  }

  .el-tabs {
    .el-tabs__header {
      z-index: 99;
      margin-bottom: 2px;
    }
    .el-tabs__content {
      flex-grow: 1;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      position: relative;
      flex-direction: column;
      .handel-content {
        .add-student {
          margin-right: 20px;
        }
      }
      .el-tab-pane {
        margin-top: 15px;
        flex-grow: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        position: relative;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;

        .student-num {
          width: 100%;
          margin-top: 20px;
          span {
            margin-right: 20px;
          }
        }

        .el-pagination {
          margin-top: 20px;
          padding: 20px 0;
        }
      }
    }
  }
}

.el-empty {
  width: 100%;
  height: 100%;
}
</style>
