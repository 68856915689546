<template>
  <div class="student-search">
    <el-form ref="form" class="ub" :model="form" label-width="70px">
      <el-form-item label="学员">
        <el-input v-model="form.nameOrtel" clearable placeholder="请输入姓名或手机号" @input="inputChange" @keyup.enter.native="enterClick"></el-input>
      </el-form-item>
      <el-button class="search-button" type="primary" size="medium" @click="seachSubmit(false)">搜索</el-button>
      <el-form-item label="学员状态" v-if="userInfo.role != 1">
        <el-select v-model="form.state" clearable @change="selectChange" placeholder="请选择" @clear="seachSubmit(true)">
          <el-option v-for="item in studentStateList" :key="item.id" :label="item.label" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>
      <el-button class="search-button" type="" size="medium" @click="lowIntentionStudentBtn()" v-if="activeTabName == 'informal'">低意向生</el-button>
    </el-form>
    <low-intention-student :isLowIntentionStu="isLowIntentionStu" @closeLowIntentionStuDialog="closeLowIntentionStuDialog"></low-intention-student>
  </div>
</template>

<script>
import LowIntentionStudent from "./LowIntentionStudent.vue";
export default {
  components: { LowIntentionStudent },
  props: {
    activeTabName: {
      type: String,
      default: "",
    },

    userInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        nameOrtel: "",
        state: "",
      },
      studentStates: [
        {
          label: "低意向生",
          id: -1,
        },
        {
          label: "游客",
          id: 0,
          type: 1,
        },
        {
          label: "咨询生",
          id: 1,
          type: 1,
        },
        {
          label: "体验生",
          id: 2,
          type: 1,
        },
        {
          label: "待分班",
          id: 3,
          type: 2,
        },
        {
          label: "在读",
          id: 4,
          type: 2,
        },
        {
          label: "结业",
          id: 5,
          type: 2,
        },

        {
          label: "结业已流失",
          id: -3,
          type: 1,
        },
      ],
      isLowIntentionStu: false,
    };
  },

  computed: {
    studentStateList() {
      return this.studentStates.filter((item) => {
        return this.activeTabName == "formal" ? item.type == 2 : item.type == 1;
      });
    },
  },

  methods: {
    /**
     * 搜索
     * @param {Boolean} isClear 是否是清除
     */
    seachSubmit(isClear) {
      if (!this.isDefine(this.form.nameOrtel) && !this.isDefine(String(this.form.state)) && !isClear) {
        this.$message({
          message: "请输入搜索条件",
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.$emit("searchTap", this.form);
    },

    /**
     * 还原搜索条件
     */
    reset() {
      this.form.state = "";
      this.form.nameOrtel = "";
    },

    /**
     * 低意向生
     */
    lowIntentionStudentBtn() {
      this.isLowIntentionStu = true;
    },

    /**
     * 关闭弹窗
     */
    closeLowIntentionStuDialog() {
      this.isLowIntentionStu = false;
      this.$emit("refresh");
    },

    /**
     * input输入框
     */
    inputChange(value) {
      if (value == "") {
        this.seachSubmit(true);
      }
    },

    enterClick() {
      this.seachSubmit();
    },

    selectChange() {
      this.seachSubmit(true);
    },
  },
};
</script>

<style lang="scss">
.student-search {
  .el-form {
    height: 35px;
    .el-form-item {
      margin-right: 20px;
      margin-bottom: 0;

      .el-form-item__label {
        line-height: 35px;
      }

      .el-form-item__content {
        line-height: 35px;
        .el-select {
          height: 35px;
        }

        .el-input {
          height: 35px;
          .el-input__inner {
            height: 35px;
            line-height: 35px;
          }
        }
      }
    }

    .search-button {
      margin-right: 20px;
    }
  }
}
</style>
