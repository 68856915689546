<!-- 续费的弹框 -->
<template>
  <div class="renew_dialog">
    <CommonDialog @close="close" :isCenter="isCenter" :showDialog="showDialog" :title="title" :width="width">
      <template v-slot:content>
        <div class="renew_content">
          <!-- 学生姓名 学生ID  学生手机号 -->
          <div class="flex_h">
            <div class="flex_h">
              <span>学生姓名：</span>
              <span class="name_str" :title="actionItem.student_name">{{ actionItem.student_name }}</span>
            </div>
            <span class="margin_left_20">学生ID：{{ actionItem.user_id }}</span>
            <span class="margin_left_20">学生手机号：{{ actionItem.tel }}</span>
          </div>
          <!-- 剩余课次数 -->
          <div class="margin_top_20">
            <span class="com_w">剩余课次数：</span>
            <span class="text_fw">{{ actionItem.balance_hour }}</span>
          </div>
          <!-- 本次续费新增课次数 -->
          <div class="margin_top_20 com_out">
            <span class="com_w">本次续费新增课次数：</span>
            <el-input
              oninput="if(!/^[0-9]+$/.test(value)) value=value.replace(/\D/g,'');if(value>100000)value=100000;if(value<=0)value=null"
              clearable
              v-model="lessonNum"
              placeholder="请输入课次数"
            ></el-input>
          </div>
          <!-- 请选择续费业绩所属老师 -->
          <div class="margin_top_20 com_out">
            <span class="com_w">请选择续费业绩所属老师：</span>
            <el-select @change="changeState" v-model="seclectTeacherName" placeholder="请选择">
              <el-option v-for="item in teacherList" :label="item.name" :value="item.name"> </el-option>
            </el-select>
          </div>

          <!-- 底部的取消和确定按钮 -->
          <div class="bom_action">
            <!-- 取消 -->
            <div class="cancel" @click.stop="close"><span>取</span><span style="margin-left: 16px">消</span></div>
            <!-- 确定 -->
            <el-button :loading="showCommitLoading" :class="['commit']" @click.stop="doSaveAction">
              <span>保</span><span style="margin-left: 16px">存</span>
            </el-button>
          </div>
        </div>
      </template>
    </CommonDialog>
  </div>
</template>
<script>
import { renewStudent } from "@/api/Api.js";
export default {
  data() {
    return {
      title: "续费加课次",

      isCenter: true,

      width: "500px",

      //是否显示loading
      isShowLoading: false,

      //提交状态值
      showLoading: false,

      //选中的老师名称,默认所有
      seclectTeacherName: "请选择",

      //确定按钮的加载状态
      showCommitLoading: false,

      //输入的新增课次数
      lessonNum: "",
    };
  },
  created() {},
  methods: {
    //关闭的方法
    close() {
      this.$emit("close");
    },

    //确定
    doSaveAction() {
      //判断输入数据的完整性
      if (this.isEmpty(this.lessonNum)) {
        this.showWarnMsg("请输入本次续费新增课次数");
        return;
      }

      if (this.isEmpty(this.seclectTeacherName) || this.seclectTeacherName == "请选择") {
        this.showWarnMsg("请选择续费业绩所属老师");
        return;
      }

      this.showCommitLoading = true;

      let teacherItem = this.teacherList.find((item) => {
        return item.name == this.seclectTeacherName;
      });

      let teacher_id = teacherItem ? teacherItem.id : "";

      let student_subjects = JSON.stringify(this.actionItem.student_subjects);

      //请求服务器
      renewStudent(this.actionItem.user_id, student_subjects, this.actionItem.school_id, teacher_id, this.lessonNum)
        .then((res) => {
          this.showCommitLoading = false;
          if (res.data.state == 200) {
            //执行成功,通知页面刷新
            this.$emit("refresh");
            //关闭页面
            this.close();
          } else {
            this.showWarnMsg(res.data.msg);
          }
        })
        .catch((err) => {
          this.showCommitLoading = false;
          this.showErrorMsg("服务器请求异常");
        });
    },

    //老师下拉框的选中事件
    changeState() {},
  },

  computed: {},
  components: {},
  props: ["showDialog", "actionItem", "teacherList"],
  computed: {},

  watch: {},
};
</script>
<style lang="scss" scoped>
.renew_dialog {
  ::v-deep .el-dialog--center .el-dialog__body {
    padding: 5px 25px 15px;
  }
  .renew_content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    ::v-deep .el-input__inner {
      height: 32px;
      line-height: 32px;
      padding-left: 6px;
    }

    ::v-deep .el-input__icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ::v-deep .el-input {
      width: 210px;
    }

    ::v-deep .el-input__suffix {
      right: 0px;
    }

    .name_str {
      width: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .com_out {
      display: flex;
      align-items: center;
      .com_w {
        width: 180px;
      }
    }
    //底部的操作按钮
    .bom_action {
      margin-top: 30px;
      height: 48px;
      display: flex;
      justify-content: center;

      .cancel,
      .commit {
        width: 120px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
      }

      .cancel {
        background: #dcdcdc;
        margin-right: 30px;
        color: black;
      }

      .commit {
        background: #409eff;
        color: white;
      }
    }
  }
}
</style>
