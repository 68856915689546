<template>
  <el-timeline class="time-line">
    <el-timeline-item v-for="(activity, index) in list" :key="index" :timestamp="activity.create_time">
      <div class="title" v-html="stuState(activity.type)"></div>
      <div class="describe">{{ activity.describe }}</div>
    </el-timeline-item>
    <el-timeline-item timestamp="现在" v-if="type == 2">
      <el-select v-model="selectValue" placeholder="请选择" clearable @change="selecChange" v-if="options.length > 0">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
      <time-line-add v-if="selectType" :selectType="selectType" @inputChange="inputChange" ref="timeLineAdd"></time-line-add>
    </el-timeline-item>
  </el-timeline>
</template>

<script>
import TimeLineAdd from "./TimeLineAdd.vue";
export default {
  components: { TimeLineAdd },
  props: {
    list: {
      type: Array,
      default: [],
    },

    type: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      selectValue: "",
      options: [
        {
          label: "到校咨询",
          value: 0,
          type: 1,
        },
        {
          label: "上课体验",
          value: 1,
          type: 2,
        },
      ],
      selectType: "",
    };
  },

  computed: {
    stuState() {
      return function (state) {
        switch (state) {
          case -2:
            return "移出低意向生";
          case -1:
            return "移入低意向生";
          case -3:
            return "状态变更为<span>结业已流失</span>";
          case 7:
            return "完成<span>续费</span>";
          case 0:
            return "进入学生池，成为<span>游客</span>";
          case 1:
            return "进校区咨询，成为<span>咨询生</span>";
          case 2:
            return "进校区上体验课，成为<span>体验生</span>";
          case 3:
            return "正式报名，成为<span>待分班学生</span>";
          case 4:
            return "被加入班级，成为<span>在读生</span>";
          case 5:
            return "课次已消耗完毕，成为<span>结业生</span>";

          default:
            return "-";
        }
      };
    },
  },

  methods: {
    selecChange(e) {
      console.log(e);
      console.log(this.options);
      let index = this.options.findIndex((item) => {
        return item.value == e;
      });
      console.log(index);
      this.selectType = e !== "" ? this.options[index].type : "";
      console.log(this.selectType);
    },

    inputChange(formData) {
      this.$emit("inputChange", formData);
    },

    clearInput() {
      this.$refs.timeLineAdd.clearInput();
    },
  },

  watch: {
    list(newVal) {
      this.selectValue = "";
      this.selectType = "";
      console.log(this.type);
      if (this.type == 2) {
        let lastType = newVal[newVal.length - 1].type;
        console.log(lastType);
        if (lastType == 1) {
          this.options.splice(0, 1);
          console.log(this.options);
        } else if (lastType == 2 || lastType == 20) {
          this.options = [];
          this.selectType = 20; //开发定义的
        }
      }
    },
  },
};
</script>

<style lang="scss">
.time-line {
  padding-left: 130px;
  height: 52vh;
  overflow-y: scroll;
  .el-timeline-item {
    .el-timeline-item__wrapper {
      .el-timeline-item__content {
        .title {
          span {
            color: #409eff;
            font-weight: bold;
          }
        }
        .describe {
          margin-top: 10px;
          font-size: 12px;
          color: rgb(71, 71, 71);
          word-wrap: break-word;
          word-break: break-all;
        }
      }
      .el-timeline-item__timestamp {
        position: absolute;
        left: -130px;
        top: 0;
        margin-top: 3px;
        width: 124px;
        text-align: right;
      }
    }
  }
}
</style>
