<template>
  <de-dialog 
        :isDialog="isStudentArchivesDialog"
        title="编辑学员档案"
        class="student-archives-dialog"
        @cancelDialog="cancelDialog"
        @confirmDialog="confirmDialog('ruleForm')"
        confirmText="保存">
            <el-form ref="ruleForm" :rules="formRules" :model="form" label-width="110px" v-if="studentArchivesInfo">
                <div class="ub">
                    <el-form-item class="default-item" label="学员姓名：" prop="name">
                        <el-input v-model="form.name" maxlength="20"></el-input>
                    </el-form-item>
                    <el-form-item class="default-item" label="学员ID：">
                        <el-input v-model="form.id" disabled></el-input>
                    </el-form-item>
                </div>
                <div class="ub">
                    <el-form-item class="default-item student-tel" label="学员手机号：" prop="tel">
                        <el-input v-model="form.tel"  :disabled="isTeacher"></el-input>
                        <div class="tip">注：更改手机号后学员登录账号也会变，请谨慎修改！</div>
                    </el-form-item>
                    <el-form-item class="default-item" label="出生日期：">
                        <el-date-picker
                            v-model="form.birthday"
                            type="month"
                            value-format="yyyy-MM"
                            placeholder="选择日期">
                            </el-date-picker>
                    </el-form-item>
                </div>
                <el-form-item class="lesson-item" label="预报名课程：">
                    <div v-if="lessonArr.length > 0">
                        <el-tag
                          :class="[isTeacher ? 'no-drop' : '']"
                           @close="handleTagClose(item)" 
                            v-for="(item, index) in lessonArr" 
                            :key="index"
                            :type="item.act ? '' : 'info'"
                            @click="tagTap(index)"
                            :disable-transitions="false">{{item.sub_name}}</el-tag>
                    </div>
                    <div v-else>
                        暂无
                    </div>
                    
                </el-form-item>
                <el-form-item class="default-item" label="学生类型">
                    <el-button :type="item.id == form.actStuType ? 'primary' : ''" 
                                v-for="(item, index) in studentTypes" 
                                :key="index" 
                                :disabled="isTeacher"
                                @click="stuTypeChange(item.id)">{{item.name}}</el-button>
                </el-form-item>
                <el-form-item class="" label="剩余课次数：">
                    <el-input-number v-model="form.lessonNum" :min="0" :max="10000" label="描述文字" :disabled="isTeacher"></el-input-number>
                </el-form-item>
                <div class="ub" v-if="studentArchivesInfo.school_id == -1 && isAdmin">
                    <el-form-item class="default-item school-item" label="关联校区：">
                        <el-input v-model="form.schoolId" clearable></el-input>
                    </el-form-item>
                    <el-button type="primary" size="medium" class="query-button" @click="querySchool">查询</el-button>
                </div>
                <div class="scool-list">
                    <el-form-item class="default-item school-item" label="">
                        {{schoolInfo ? '学校：' + schoolInfo.school_name : ''}}
                    </el-form-item>
                    
                </div>
            </el-form>
  </de-dialog>
</template>

<script>
import DeDialog from '../../../../components/DeDialog.vue'
export default {
  components: { DeDialog },
  props: {
      isStudentArchivesDialog: {
          type: Boolean,
          default: false
      },

      studentArchivesInfo: {
        type: Object,
        default: () => {
            return {}
        }
      }
  },

  data() {
      // 自定义验证
      const validPhone = (rule, value, callback) => {
        if (!value) {
            callback(new Error('请输入电话号码'))
        } else if (!this.testPhone(value)) { //判断用户输入的值是否符合规则
            callback(new Error('请输入正确的11位手机号码'))
        } else {
            callback()
        }
      }
      return {
          form: {
              name: '',
              id: 123123,
              tel: '',
              birthday: '',
              lessonNum: '',
              schoolId: '',
              actStuType: ''
          },
          formRules: {
              name: [{ required: true, message: '请输入学员姓名', trigger: 'blur' }],
              tel: [{ required: true, validator: validPhone, trigger: 'blur' }]
          },
          lessonArr: [],
          schoolInfo: "",
          studentTypes: [{
                id: 1,
                name: '非正式生'
            }, {
                id: 2,
                name: '正式生'
            }]
      }
  },

  created() {

  },

  computed: {
    /**
     * 老师
     */
    isTeacher() {
        return this.userInfo.role == 1
    },

    /**
     * 校长
     */
    isHeadmaster() {
        return this.userInfo.role == 3
    },

    /**
     * 管理员
     */
    isAdmin() {
        return this.userInfo.role == 4 || this.userInfo.role == 5
    }
  },

  methods: {
      initStuInfo() {
        this.userInfo = this.getUserInfo();
        this.form = {
            name: this.studentArchivesInfo.student_name,
            id: this.studentArchivesInfo.user_id,
            tel: this.studentArchivesInfo.tel,
            birthday: this.studentArchivesInfo.birthday,
            forecastLessonArr: this.studentArchivesInfo.student_sub_name ? this.studentArchivesInfo.student_sub_name.split(',') : '',
            lessonNum: this.studentArchivesInfo.balance_hour,
            actStuType: this.studentArchivesInfo.join_type
        }
      },

      cancelDialog() {
        this.$emit('cancelArchivesDialog')
      },

      confirmDialog(formName) {
        console.log(formName)
        this.$refs[formName].validate((valid) => {
            console.log(valid)
          if (valid) {
            if (!this.isDefine(this.schoolInfo) && this.isAdmin && this.studentArchivesInfo.school_id == -1) {
                this.$message({
                    message: '请输入关联学校',
                    type: 'warning'
                })
                return
            }

            this.$confirm(`是否确认修改?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.submitStudentInfo()
            }).catch(() => {
                // 取消         
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },

      /**
       * 提交学员数据
       */
      submitStudentInfo() {
        console.log('aaa')
        let arrSubjects = this.lessonArr.filter(item => {
            return item.act
        })
        console.log(arrSubjects)
        this.$http
            .fetchPost(this.$api.StudentManageController, {
                type: 2,
                student_id: this.studentArchivesInfo.user_id,
                name: this.form.name,
                tel: this.form.tel,
                birthday: this.form.birthday,
                balance_hour: this.form.lessonNum,
                arr_subjects: JSON.stringify(arrSubjects),
                school_id: this.studentArchivesInfo.school_id == -1 ? this.schoolInfo.school_id : this.studentArchivesInfo.school_id,
                join_type: this.form.actStuType,
                uid: this.userInfo.id,
                token: this.userInfo.token
            })
            .then((res) => {
                console.log(res)
                if (res.data.state == 200) {
                    this.$message({
                        message: '提交成功',
                        type: 'success'
                    })
                    setTimeout(() => {
                        this.$emit('cancelArchivesDialog', true)
                    }, 1500)
                } else {
                    this.showErrorMsg(this.getMesage(res.data.state));
                }
            }).catch((err) => {
                
            })
      },

      /**
       * 获取预报名课程
       */
      getForecastLesson() {
        this.$http
            .fetchPost(this.$api.SUBJECT_MANAGER, {
                type: 4,
                school_id: this.userInfo.schoolId,
                uid: this.userInfo.id,
                token: this.userInfo.token
            })
            .then((res) => {
                console.log(res)
                if (res.data.state == 200) {
                    console.log(this.form.forecastLessonArr)
                    let list = res.data.data
                    console.log(list)
                    for (let i = 0; i < this.form.forecastLessonArr.length; i++) {
                        console.log('i---', this.form.forecastLessonArr[i])
                        let index = list.findIndex((item) => {
                            return item.sub_name == this.form.forecastLessonArr[i]
                        })
                        console.log(index)
                        list[index].act = true
                        console.log(list)
                    }
                    this.lessonArr = list

                } else {
                    this.showErrorMsg(this.getMesage(res.data.state));
                }
            }).catch((err) => {
                
            })
      },

      /**
       * 关联校区查询
       */
      querySchool() {
        if(!this.isDefine(this.form.schoolId)) {
            this.$message({
                message: '请输入校区ID',
                type: 'warning'
            })
            return
        }
        console.log(this.form.schoolId)
        this.$http
            .fetchPost(this.$api.SCHOOL_MANAGER, {
                type: 3,
                school_id: this.form.schoolId,
                uid: this.userInfo.id,
                token: this.userInfo.token
            })
            .then((res) => {
                console.log(res)
                if (res.data.state == 200) {
                    this.schoolInfo = res.data.data
                } else {
                    this.$message({
                        message: '未搜索到该校区',
                        type: 'error'
                    })
                }
                
            }).catch((err) => {
            })
      },

      /**
       * 点击预报名标签
       */
      tagTap(index) {
        if (this.isTeacher) {
            return
        }
        this.$set(this.lessonArr[index], 'act', !this.lessonArr[index].act)
      },

      /**
       * 删除预报名课程
       */
      handleTagClose(item) {
        this.lessonArr.splice(this.lessonArr.indexOf(item), 1);
      },

      stuTypeChange(id) {
        this.form.actStuType = id
      }
  },

  watch: {
    isStudentArchivesDialog(newVal) {
        if (newVal) {
            console.log(this.studentArchivesInfo)
            this.initStuInfo()
            this.lessonArr = [],
            this.schoolInfo = ""
            this.getForecastLesson()
        }
        
    }
  }
}
</script>

<style lang="scss">
.student-archives-dialog {
    .el-dialog {
        min-width: 710px;
        .el-form {
            .default-item {
                width: 320px;
            }

            .student-tel {
                .tip {
                    color: red;
                    line-height: 18px;
                    margin-top: 8px;
                    font-size: 12px;
                }
            }

            .lesson-item {
                .el-tag {
                    margin-right: 15px;
                    cursor: pointer;
                }

                .el-tag.no-drop {
                    cursor: no-drop;
                }
            }

            .el-input-number {
                width: 220px;
            }

            .query-button {
                height: 40px;
                margin-left: 15px;
            }
        }

    }
}
</style>