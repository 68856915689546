<template>
  <el-dialog 
      :visible.sync="isDialog"
      :close-on-click-modal="false"
      :width="width"
      :title="title"
      custom-class="default-dialog"
      :before-close="handleClose">
        <slot></slot>
      <span slot="footer" class="dialog-footer" v-if="isFooter">
          <el-button size="medium" @click.native="cancelDialog">取消</el-button>
          <el-button size="medium" @click.native="confirmDialog" type="primary">{{confirmText}}</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
    components: {
        
    },

    props: {
        isDialog: {
            type: Boolean,
            default: false
        },

        isFooter: {
            type: Boolean,
            default: true
        },

        title: {
            type: String,
            default: '标题'
        },

        width: {
            type: String,
            default: '800'
        },

        confirmText: {
            type: String,
            default: '确定'
        }
    },

    methods: {
        cancelDialog() {
            this.$emit('cancelDialog')
        },

        confirmDialog() {
            this.$emit('confirmDialog')
        },

        handleClose() {
            this.cancelDialog()
        }
    },

    watch: {
        // ...
    }
}
</script>

<style lang="scss">
.default-dialog {
    .el-dialog__header {
           
    }

    .el-dialog__footer {
        .dialog-footer {
            display: block;
            text-align: center;
        }
    }
}
</style>