<template>
    <de-dialog 
        :isDialog="isLowIntentionStu"
        :isFooter="false"
        title="低意向生"
        class="low-intention-student-dialog"
        @cancelDialog="cancelAddStuDialog"
        @confirmDialog="confirmAddStuDialog('ruleForm')"
        confirmText="保存">
        <div class="content" v-loading="loading">
            <div v-if="list">
                <el-table
                    :data="list"
                    style="width: 100%">
                    <el-table-column
                        label="序号"
                        type="index"
                        width="50"
                        align="center"> 
                    </el-table-column>
                    <el-table-column
                        prop="student_name"
                        label="姓名"
                        width="180"
                        align="center">
                    </el-table-column>
                    <el-table-column
                        prop="tel"
                        label="手机号"
                        width="180"
                        align="center">
                    </el-table-column>
                    <el-table-column
                        prop="delete_time"
                        label="删除时间"
                        width="180"
                        align="center">
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        align="center">
                        <template slot-scope="scope">
                            <el-button @click="handleClick(scope.row)" type="text" size="small">恢复</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </de-dialog>
</template>

<script>
import DeDialog from '../../../../components/DeDialog.vue'

export default {
    components: { DeDialog },
    props: {
        isLowIntentionStu: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            loading: false,
            list: null
        }
    },

    methods: {
        cancelAddStuDialog() {
            this.$emit('closeLowIntentionStuDialog')
        },

        getData() {
            this.loading = true;
            this.$http
                .fetchPost(this.$api.StudentManageController, {
                    type: 5,
                    name_tel: '',
                    school_id: this.userInfo.schoolId,
                    uid: this.userInfo.id,
                    token: this.userInfo.token,
                    role: this.userInfo.role,
                })
                .then((res) => {
                    console.log(res);
                    this.loading = false;
                    if (res.data.state == 200) {
                        this.list = res.data.data.data_list
                    } else {
                        this.showErrorMsg(this.getMesage(res.data.state));
                    }
                })
                .catch((err) => {
                    this.loading = false;
                });
        },

        handleClick(item) {
            this.$http
                .fetchPost(this.$api.StudentManageController, {
                    type: 9,
                    student_ids: item.user_id,
                    school_id: this.userInfo.schoolId,
                    uid: this.userInfo.id,
                    token: this.userInfo.token,
                    role: this.userInfo.role,
                })
                .then((res) => {
                    console.log(res);
                    if (res.data.state == 200) {
                        this.$message({
                            message: '恢复成功',
                            type: 'success'
                        })
                        this.getData()
                    } else {
                        this.showErrorMsg(this.getMesage(res.data.state));
                    }
                })
                .catch((err) => {
                });
        }
    },

    watch: {
        isLowIntentionStu(newVal) {
            if (newVal) {
                this.userInfo = this.getUserInfo();
                this.getData()
            }
        }
    }
}
</script>

<style lang="scss">
.low-intention-student-dialog {
    .content {
        min-height: 200px;
    }
}
</style>