<template>
  <de-dialog 
        :isDialog="isAddStuDialog"
        title="新增学员"
        class="add-student-dialog"
        @cancelDialog="cancelAddStuDialog"
        @confirmDialog="confirmAddStuDialog('ruleForm')"
        confirmText="保存">
            <div v-loading="loading">
                <el-form class="add-student-info" :rules="formRules" ref="ruleForm" :model="form" label-width="100px" v-if="!loading && loadState == 200">
                    <div class="ub">
                        <el-form-item label="学员姓名" prop="name">
                            <el-input v-model="form.name" clearable maxlength="20"></el-input>
                        </el-form-item>
                        <el-form-item label="出生日期">
                            <el-date-picker
                                v-model="form.birthday"
                                type="month"
                                @change="timeChange"
                                value-format="yyyy-MM"
                                :picker-options="setMonthDisabled"
                                placeholder="选择日期">
                                </el-date-picker>
                        </el-form-item>
                    </div>
                    <el-form-item class="default-item" label="学员手机号" prop="tel">
                        <el-input v-model="form.tel"></el-input>
                    </el-form-item>
                    <el-form-item class="lesson" label="预报名课程">
                        <el-button :type="item.isCheck ? 'primary' : ''" v-for="(item, index) in lessonList" :key="index" @click="lessonChange(index)">{{item.sub_name}}</el-button>
                    </el-form-item>
                    <el-form-item class="default-item" label="学生类型">
                        <el-button :type="item.id == actStuType ? 'primary' : ''" v-for="(item, index) in studentTypes" :key="index" @click="stuTypeChange(item.id)">{{item.name}}</el-button>
                    </el-form-item>
                    <el-form-item class="default-item" label="有效课次数">
                        <el-input-number v-model="form.lessonNum" :min="0" :max="10000" label="描述文字"></el-input-number>
                    </el-form-item>
                </el-form>
            </div>
            
  </de-dialog>
</template>

<script>
import DeDialog from '../../../../components/DeDialog.vue'
export default {
  components: { DeDialog },
  props: {
      isAddStuDialog: {
          type: Boolean,
          default: false
      }
  },

  data() {
      // 自定义验证
      const validPhone = (rule, value, callback) => {
        if (!value) {
            callback(new Error('请输入电话号码'))
        } else if (!this.testPhone(value)) { //判断用户输入的值是否符合规则
            callback(new Error('请输入正确的11位手机号码'))
        } else {
            callback()
        }
      }
      return {
          form: {
              name: '',
              tel: '',
              birthday: '',
              lessonNum: 0
          },
          formRules: {
              name: [{ required: true, message: '请输入学员姓名', trigger: 'blur' }],
              tel: [{ required: true, validator: validPhone, trigger: 'blur' }]
          },
          lessonList: null,
          actLesson: [],
          // loading
          loading: false,
          loadState: 200,
          loadText: '',
          setMonthDisabled: {
            disabledDate(time) {
                // 获取当前的月份信息
                const date = new Date(); // 获取当前的时间基本信息，值是这样的： Tue Jul 20 2021 14:59:43 GMT+0800 (中国标准时间)
                const year = date.getFullYear(); // 获取当前年份，值是这样的： 2021
                let month = date.getMonth() + 1; // 获取当前月份，值是这样的： 6   getMonth()方法返回值是0-11，也就是1月份到12月份，所以要加上1，才是当前月份
                if (month >= 1 && month <= 9) {
                    // 如果是1月到9月就要在前面补上一个0   比如：02、07    月份这样表示
                    month = "0" + month;
                }
                const nowDate = year.toString() + month.toString(); // 转换成字符串拼接，最终得到年和月，比如此时的时间是2021年7月20号，所以nowDate的值是：202107

                // 获取时间选择器的月份信息
                const timeyear = time.getFullYear(); // 获取时间选择器的年份（有很多）
                let timemonth = time.getMonth() + 1; // 与上面同理
                if (timemonth >= 1 && timemonth <= 9) {
                    timemonth = "0" + timemonth;
                }
                const elTimeData = timeyear.toString() + timemonth.toString();

                // 返回，时间选择器的日期月份大于当前日期的月份，又因为disabledDate函数是控制月份禁用不可选
                // 所以，最终就是：时间选择器的月份大于当前的月份，就都禁用掉，所以就实现了最终效果：
                // 大于等于当前月份都不可选 
                return elTimeData > nowDate || elTimeData < 197001; // 这里虽然是字符串，但是弱类型语言js会做一个转换，是可以比较大小的如： '202107' > '202008'
            },
          },


            studentTypes: [{
                id: 1,
                name: '非正式生'
            }, {
                id: 2,
                name: '正式生'
            }],
            actStuType: 1
      }
  },

  created() {
    this.userInfo = this.getUserInfo();
  },

  methods: {
      /**
       * 关闭弹窗
       */
      cancelAddStuDialog() {
        this.$emit('cancelAddStuDialog')
        this.form = {
            name: '',
            tel: '',
            birthday: '',
            lessonNum: ''
        }
        this.resetForm('ruleForm')
      },
      
      /**
       * 提交
       */
      confirmAddStuDialog(formName) {
        console.log(formName)
        this.$refs[formName].validate((valid) => {
            console.log(valid)
          if (valid) {
            this.saveStudent()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },

      /**
       * 保存学员
       */
      saveStudent() {
        if(this.actStuType == 2 && this.form.lessonNum == 0) {
            this.$message({
                message: '正式生课时数不能为0',
                type: 'warning'
            })
            return
        }
        let actLesson = this.lessonList.filter(item => {
            return item.isCheck
        })
        this.loading = true
        this.$http
            .fetchPost(this.$api.StudentManageController, {
                type: 1,
                name: this.form.name,
                tel: this.form.tel,
                birthday: this.form.birthday,
                total_hour: this.form.lessonNum,
                arr_subjects: JSON.stringify(actLesson),
                join_type: this.actStuType,
                school_id: this.userInfo.schoolId,
                uid: this.userInfo.id,
                token: this.userInfo.token,
            })
            .then((res) => {
                console.log(res)
                this.loading = false

                if(res.data.state == 200) {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    })
                    setTimeout(() => {
                        this.cancelAddStuDialog()
                        this.$emit('addStuSucess')
                    }, 1500)
                } else {
                    this.showErrorMsg(this.getMesage(res.data.state));
                }
                
            }).catch((err) => {
                
            })
      },
      
      /**
       * 重置form
       */
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },

      handleChange() {

      },

      /**
       * 获取学校加盟的平台课程列表
       */
      getLessonList() {
        this.loading = true
        this.$http
            .fetchPost(this.$api.SUBJECT_MANAGER, {
                type: 4,
                school_id: this.userInfo.schoolId,
                uid: this.userInfo.id,
                token: this.userInfo.token,
            })
            .then((res) => {
                console.log(res)
                if(res.data.state == 200) {
                    this.loading = false
                    this.loadState = res.data.state
                    this.loadText = res.data.msg
                    this.lessonList = res.data.data
                } else {
                    this.showErrorMsg(this.getMesage(res.data.state));
                }
            }).catch((err) => {
                this.loading = false
                this.loadState = -1,
                this.loadText =  '网络错误'
            })
      },

      /**
       * 选择课程
       */
      lessonChange(index) {
        let item = this.lessonList[index]
        item.isCheck = !item.isCheck
        this.$set(this.lessonList, index, item)
      },

      /**
       * 学生类型
       */
      stuTypeChange(id) {
        this.actStuType = id
      },

      timeChange(val) {
        console.log(val)
      }
  },

  watch: {
    isAddStuDialog(newVal) {
        if (newVal) {
            this.getLessonList()
        }
    }
  }
}
</script>

<style lang="scss">
.add-student-dialog {
    min-height: 400px;
    .el-dialog {
        min-width: 750px;
        .add-student-info {
            .el-form-item.default-item {
                width: 292px;
            }

            .el-form-item.lesson {
                .el-button {
                    margin-left: 0;
                    margin-right: 10px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}
</style>